import { Modal } from 'antd';
import Button from '../Button';
import Image from './Image';

import GoogleIcon from '../../assets/google.svg';
import RabbyIcon from '../../assets/rabby.svg';

import './index.scss';

interface IProps {
  title1: string;
  title2: string;
  pictures: [string, string, string];
}

const installModalConfirm = ({ title1, title2, pictures }: IProps) => {
  Modal.success({
    className: 'install-modal',
    width: 800,
    title: title1,
    icon: null,
    centered: true,
    closable: true,
    maskClosable: true,
    footer: (
      <Button
        width={320}
        height={56}
        fontSize={20}
        onClick={() => {
          Modal.destroyAll();
        }}
      >
        OK
      </Button>
    ),
    content: (
      <>
        <div className="install">
          <div className="section1">
            <div>1. Install and open</div>
            <div className="rabby-brand">
              <img src={RabbyIcon} alt="" />
              <div className="name">Rabby Wallet</div>
            </div>
          </div>
          <div
            className="btn"
            onClick={() =>
              window.open(
                'https://chromewebstore.google.com/detail/rabby-wallet/acmacodkjbdgmoleebolmdjonilkdbch',
                '_blank'
              )
            }
          >
            <img src={GoogleIcon} alt="" />
            <div>Download for Chrome</div>
          </div>
        </div>
        <div className="tour">
          <div>2. {title2}</div>
          <div className="pictures">
            {pictures.map((str) => (
              <Image key={str} src={str} alt="" />
            ))}
          </div>
        </div>
      </>
    ),
  });
};

export default installModalConfirm;
