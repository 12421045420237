import React, { useState } from 'react';
import { Skeleton } from 'antd'

interface IProps {
  src: string;
  alt: string;
}
const Image: React.FC<IProps> = ({ src, alt }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      {!imageLoaded && <Skeleton.Input className='img-skeleton' active />}
      <img
        src={src}
        alt={alt}
        style={{ display: imageLoaded ? 'block' : 'none' }}
        onLoad={() => setImageLoaded(true)}
      />
    </>
  );
};

export default Image;