import React from 'react';
import dbkLogo from '../../assets/dbk-logo.svg';

import './index.scss';

interface IProps {
  color: string;
}
const Brand: React.FC<IProps> = ({ color }) => {
  return (
    <div
      className="brandContainer cursor-pointer"
      style={{ color }}
      onClick={() => {
        window.location.reload();
      }}
    >
      <div className="icon">
        <img src={dbkLogo} alt="" />
      </div>
      <div className="name">DBK Chain</div>
    </div>
  );
};

export default Brand;
