import React from 'react'
import Brand from './Brand'

const Footer: React.FC = () => {
  return(
    <div className='brand-footer flex justify-between w-[96%] items-center'>
      <Brand color='var(--d-neutral-title1)'/>
      <div className='text-[var(--d-neutral-body)] text-[14px] font-[16.8px] text-right'>
        <div>© 2024 DBK Chain All rights reserved</div>
      </div>
    </div>  
  )
}

export default Footer
