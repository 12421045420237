import React from 'react';
import './index.scss';

interface IProps {
  width: number;
  height: number;
  fontSize: number;
  showBorder?: boolean;
  children: React.ReactNode;
  onClick?: () => void,

}
const Button: React.FC<IProps> = ({
  width,
  showBorder,
  height,
  fontSize,
  children,
  onClick
}) => {
  return (
    <div
      style={{
        width,
        height,
        fontSize,
        borderWidth: showBorder ? 2 : 0,
      }}
      onClick={onClick}
      className="button"
    >
      {children}
    </div>
  );
};

export default Button;
