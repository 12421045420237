import Header from './components/Header';
import Footer from './components/Footer';
import Button from './components/Button';
import installModalConfirm from './components/InstallModal';
import ArrowRightIcon from './assets/arrow-right.svg';
import DeBankIcon from './assets/debank.svg';
import RabbyIcon from './assets/rabby.svg';
import GenesisImg from './assets/genesis.png';
import LinkChainImg from './assets/link-chain.png';
import MainPage from './assets/main.png';
import Ecology from './assets/ecology.png';
import ToBridge from './assets/toBridge.png';
import ToNFT from './assets/toNFT.png';
import DarkMainPage from './assets/dark/main-page.png';
import DarkEcology from './assets/dark/ecology.png';
import DarkToBridge from './assets/dark/bridge.png';
import DarkToNFT from './assets/dark/nft.png';

import useTheme from './useTheme';

import './app.scss';
import { message } from 'antd';

const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    userAgent.toLowerCase()
  );
};

export default function App() {
  const { isDarkMode } = useTheme();
  return (
    <div className="dbk-container">
      <section className="relative header">
        <Header />
        <span className="block border-b-[0.5px] border-white border-opacity-20 "></span>
        <div>
          <main className="w-[96%] max-w-[1200px] mx-auto">
            <div className="title mt-[120px] text-[90px] leading-[81px] font-[900] text-white">
              DBK Chain
            </div>
            <div className="desc mt-[32px] text-[46px] leading-[55px] font-[500] text-white">
              A Layer2 chain built on the OP Stack
            </div>
            <div className="btn mt-[64px]">
              <Button
                width={248}
                height={64}
                fontSize={24}
                showBorder
                onClick={() =>
                  window.open('https://docs.dbkchain.io/', '_blank')
                }
              >
                <div>Start Building</div>
                <img width={28} src={ArrowRightIcon} alt="" />
              </Button>
            </div>
          </main>
        </div>
      </section>
      <section id="bridge">
        <div className="flex items-center w-[96%] h-full max-w-[1200px] mx-auto">
          <div>
            <img src={LinkChainImg} alt="" />
          </div>
          <div>
            <div>
              <header className="text-[48px] leading-[58px] font-[700]">
                Bridge to DBK Chain{' '}
              </header>
              <main className="mt-[50px] text-[28px] leading-[33px] font-[400]">
                Bridge from Ethereum to DBK Chain, Powered by Op SuperBridge
              </main>
            </div>
            <div className="btn mt-[145px]">
              <Button
                width={271}
                height={56}
                fontSize={20}
                onClick={() => {
                  if (isMobile()) {
                    message.info({
                      className: 'tips',
                      icon: null,
                      content: 'Please visit this site from the desktop',
                    });
                    return;
                  }
                  installModalConfirm({
                    title1: 'Bridge to DBK Chain on Rabby Wallet',
                    title2: `Click "Ecosystem," select "DBK Chain," and click on "Bridge to DBK Chain."`,
                    pictures: isDarkMode
                      ? [DarkMainPage, DarkEcology, DarkToBridge]
                      : [MainPage, Ecology, ToBridge],
                  });
                }}
              >
                Bridge on Rabby Wallet
              </Button>
            </div>
          </div>
        </div>
      </section>
      <section id="mint">
        <div className="w-[96%] max-w-[1200px] mx-auto h-full flex items-center justify-between">
          <div className="w-full max-w-[706px]">
            <header className="text-[48px] leading-[58px] font-[700]">
              Mint DBK Genesis NFT
            </header>
            <main className="mt-[37px] text-[28px] leading-[33px] font-[400]">
              Be a witness to DBK Chain and anticipate remarkable innovations
            </main>
            <div className="btn mt-[101px]">
              <Button
                width={256}
                height={56}
                fontSize={20}
                onClick={() => {
                  if (isMobile()) {
                    message.info({
                      className: 'tips',
                      icon: null,
                      content: 'Please visit this site from the desktop',
                    });
                    return;
                  }
                  installModalConfirm({
                    title1: 'Mint DKB Genesis NFT on Rabby Wallet',
                    title2: `Click "Ecosystem," select "DBK Chain," and click on "Mint DBK Genesis NFT."`,
                    pictures: isDarkMode
                      ? [DarkMainPage, DarkEcology, DarkToNFT]
                      : [MainPage, Ecology, ToNFT],
                  });
                }}
              >
                Mint on Rabby Wallet
              </Button>
            </div>
          </div>
          <div className="btn w-[400px] h-[400px] flex justify-center items-center">
            <img className="w-[400px]" src={GenesisImg} alt="" />
          </div>
        </div>
      </section>
      <section className="partner w-[96%]">
        <div>
          <header>Partner</header>
          <main>
            <section
              className="cursor-pointer"
              onClick={() => window.open('https://debank.com/', '_blank')}
            >
              <div className="hover-wrapper">
                <img src={DeBankIcon} alt="" />
                <div>DeBank</div>
              </div>
            </section>
            <section
              className="cursor-pointer"
              onClick={() => window.open('https://rabby.io/', '_blank')}
            >
              <div className="hover-wrapper">
                <img src={RabbyIcon} alt="" />
                <div>Rabby Wallet</div>
              </div>
            </section>
            <section
              onClick={() => window.open('https://docs.dbkchain.io/', '_blank')}
            >
              Join us to be next
            </section>
          </main>
        </div>
      </section>
      <div className="brand">
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
