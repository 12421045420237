import React from 'react';
import Brand from '../Brand';
import xLogo from '../../assets/x.svg';
import './index.scss';

const Header: React.FC = () => {
  return (
    <header className="nav flex justify-center bg-transparent items-center w-full gap-[70px] h-[72px] max-w-[1200px]">
      <div>
        <Brand color="rgba(255, 255, 255, 1)" />
      </div>
      <div className="actions">
        <div onClick={() => window.open('https://docs.dbkchain.io/', '_blank')}>
          Build
        </div>
        <div
          onClick={() => {
            document.getElementById('bridge')?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          Bridge
        </div>
        <div
          onClick={() => {
            document.getElementById('mint')?.scrollIntoView({
              behavior: 'smooth',
            });
          }}
        >
          Mint NFT 🔥
        </div>
        <div onClick={() => window.open('https://scan.dbkchain.io/', '_blank')}>
          Scan
        </div>
      </div>
      <div
        className="link"
        onClick={() => window.open('https://x.com/dbkchain', '_blank')}
      >
        <img src={xLogo} alt="" />
      </div>
    </header>
  );
};

export default Header;
